import React, { useState } from 'react';
import Layout from '../components/Layout';
import { useResources } from '../custom-query-hooks/useResources';
import ResourceCard from '../components/elements/ResourceCard';
import '../assets/global.css';

const Resources = function Resources() {
  const resourcesList = useResources();
  const [resources, setResources] = useState(resourcesList);
  const [filter, setFilter] = useState('all');

  const filterResources = (tagName) => {
    setFilter(tagName);
    if (tagName === 'all') {
      setResources(resourcesList);
      return;
    }

    const newResources = [];
    resourcesList.forEach((resource) => {
      const { tags } = resource;
      tags.map((mappedTag) => mappedTag.name.toLowerCase() === tagName.toLowerCase()
          && newResources.push(resource));
    });
    setResources(newResources);
  };

  const allTags = {};

  resourcesList.forEach((resource) => {
    resource.tags.forEach((tag) => {
      if (allTags[tag.name]) {
        allTags[tag.name] += 1;
      } else {
        allTags[tag.name] = 1;
      }
    });
  });

  const newTags = Object.entries(allTags).sort((a, b) => {
    const [firstTag] = a;
    const [secondTag] = b;
    return firstTag.localeCompare(secondTag);
  });

  return (
    <Layout>
      <main className="resources-container">
        <div className="tags-container">
          <p className="tags-title">Tags</p>
          { filter === 'all' ? (
            <span className="tag-container selected-tag">Show All</span>
          ) : (
            <button
              type="button"
              onClick={() => filterResources('all')}
              className="tag-container"
            >
              Show All
            </button>
          )}
          {
          newTags.map((tagArray) => {
            const [tagName, value] = tagArray;
            if (filter === tagName.toLowerCase()) {
              return (
                <span
                  key={tagArray}
                  className="tag-container selected-tag"
                >
                  {tagName}
                  {' '}
                  <span className="tag-number selected-tag-number">{value}</span>
                </span>
              );
            }
            return (
              <button
                type="button"
                key={tagName}
                onClick={() => filterResources(tagName.toLowerCase())}
                className="tag-container"
              >
                {tagName}
                {' '}
                <div className="tag-number">
                  <span>{value}</span>
                </div>
              </button>
            );
          })
        }
        </div>
        {
        resources.map((resource) => <ResourceCard key={resource.title} resource={resource} />)
        }
      </main>
    </Layout>
  );
};

export default Resources;
