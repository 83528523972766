import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import slugify from 'slugify';

const ResourceCard = function ResourceCard({ resource }) {
  const {
    title, date, image, tags,
  } = resource;
  const slug = slugify(title, { lower: true });

  return (
    <Link
      className="resource-card-container"
      to={`/resources/${slug}`}
      title={title}
    >
      <div className="resource-card-title-container">
        <p className="resource-card-title">{title}</p>
        <p className="resource-card-divider">|</p>
        <p className="resource-card-date">{date}</p>
      </div>
      <div className="resource-card-image-container">
        <img className="resource-card-image" src={image.file.url} alt={image.file.fileName} />
      </div>
      <div className="resource-card-tags">
        {tags.map((tag) => (
          <p
            key={tag.name}
          >
            {tag.name}
          </p>
        ))}
      </div>
    </Link>
  );
};

ResourceCard.propTypes = {
  resource: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    image: PropTypes.shape({
      file: PropTypes.shape({
        fileName: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  }),
};

ResourceCard.defaultProps = {
  resource: null,
};

export default ResourceCard;
