import { graphql, useStaticQuery } from 'gatsby';

/* eslint-disable import/prefer-default-export */
export const useResources = () => {
  const { allContentfulResource } = useStaticQuery(
    graphql`
      {
        allContentfulResource {
          nodes {
            title
            date(formatString: "MM/DD/YYYY")
            image {
              file {
                url
                fileName
              }
            }
            tags {
              name
            }
          }
        }
      }
    `,
  );
  return allContentfulResource.nodes;
};
